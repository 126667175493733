<template>
  <section class="change-select-product">
    <div class="container">
      <div class="wrapper">
        <h1 class="section-title ta--center">
          Выберите продукт
        </h1>
        <div class="divider" />
        <p class="description ta--center">
          Выберите один из представленных продуктов компании CLICKTOID, <br> который вы уже приобрели.
        </p>
      </div>
    </div>
    <swiper
      v-if="productsToActivate.length"
      ref="swiper"
      class="swiper"
      :class="{ padding : productsToActivate.length > 2 }"
      :options="swiperOptions"
    >
      <swiper-slide
        v-for="item in productsToActivate"
        :key="item.id"
        :product="item"
        class="product"
      >
        <product-item :product="item" operation-type="Сменить держателя" @click.native="$emit('next-slide')" />
      </swiper-slide>
    </swiper>
  </section>
</template>

<script>
import ProductItem from '@/components/ProductItem'

export default {
  name: 'ChangeSelectProduct',
  components: {
    ProductItem
  },
  data () {
    return {
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 40,
        freeMode: true,
        breakpoints: {
          576: {
            slidesPerView: 2,
            spaceBetween: 80
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 120
          }
        }
      },
      productsToActivate: [
        {
          id: 1,
          img: 'keys.png',
          title: 'Ключи на месте',
          snippet: 'Брелок для ключей с сервисом на 1 год',
          description: 'С услугой «Ключи на месте» Вы минимизируете риски от потери своих ключей! Брелок CLICKTOID имеет уникальный ID номер, информацию для Нашедшего и телефон круглосуточной Службы поддержки.'
        },
        {
          id: 2,
          img: 'birka.png',
          title: 'Любимый друг',
          snippet: 'Кулон для животного с сервисом на 1 год',
          description: 'Услуга «Любимый друг» предоставит Вам возможность оперативно получить информацию о метонахождении Вашего питомца, если он потерялся!'
        }
      ]
    }
  },
  created () {
    this.swiperOptions.centeredSlides = this.productsToActivate.length < 3
  }
}
</script>

<style lang="scss" scoped>
  .change-select-product {
    .container {
      flex-grow: 0;
    }
    .wrapper {
      max-width: 864px;
    }
    .swiper {
      width: 100%;
      @include max-w-xs {
        padding-left: 40px;
      }
      &.padding {
        padding-left: 120px;
      }
      .product {
        max-width: 305px;
      }
    }
  }
</style>
