<template>
  <section class="change-hero">
    <div class="container">
      <div class="wrapper">
        <div class="content">
          <h1 class="section-title">
            Смена держателя продукта <span class="red-text">CLICK</span>
          </h1>
          <div class="divider" />
          <p class="description mb-m">
            Благодаря предварительной маркировке личных вещей специальными метками (брелоками, наклейками, браслетами, кулонами, багажными бирками) с уникальным идентификационным (ID) номером, а также уникальным разработкам в области мобильных приложений по защите личных вещей, КликТуАйДи эффективно помогает вернуть владельцу потерянные вещи из любой точки мира.
          </p>
          <app-link class="mb-s" to="/activation#hero">
            Активация продукта
          </app-link>
          <app-link class="mb-s" to="/renewal#hero">
            Продление обслуживания
          </app-link>
          <app-button
            class="to-activation mb-xl"
            title="К смене держателя"
            description="Активация, продления, смена держателя"
            @click.native="$emit('next-slide')"
          >
            <icon-arrow-right class="icon" />
          </app-button>
        </div>
        <div class="wrapper-img img-circle">
          <img src="@/assets/img/activation/tools.png">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AppButton from '@/components/common/AppButton'
import AppLink from '@/components/common/AppLink'
import IconArrowRight from '@/assets/img/icons/arrow-right.svg'

export default {
  name: 'ChangeHero',
  components: {
    AppButton,
    AppLink,
    IconArrowRight
  }
}
</script>

<style lang="scss" scoped>
  .change-hero {
    &.active {
      .content {
        left: 0;
      }
      .wrapper-img {
        right: 0;
      }
    }
    .container {
      height: 100%;
      justify-content: center;
      .wrapper {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include max-w-md {
          height: auto;
          flex-direction: column-reverse;
        }
      }
    }
    .content, .wrapper-img {
      position: relative;
      transition: 1s;
    }
    .wrapper-img {
      right: -2000px;
    }
    .content {
      left: -2000px;
      margin-right: 40px;
      max-width: 416px;
      @include max-w-md {
        margin-right: 0;
      }
      .red-text {
        color: #F24361;
      }
      .to-activation {
        max-width: 300px;
        margin-top: 80px;
        @include max-w-md {
          margin-top: 0;
        }
        .icon {
          transform: rotate(90deg);
        }
        @include max-w-md {
          margin-bottom: 40px;
        }
      }
    }
  }
</style>
